.react_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  z-index: 1000;
}

.modal_content {
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  min-height: 3rem;
  display: flex;
  border-radius: 10px;
}

.modal_content.full {
  width: calc(100vw - 3rem);
}

.react_modal.show {
  pointer-events: visible;
  opacity: 1;
}

.react_modal.show .modal_content {
  transform: translateY(3rem);
}

.react_modal.fade {
  pointer-events: none;
  opacity: 0;
}

.react_modal.fade .modal_content {
  transform: translateY(-200px);
}

.react_modal.centered {
  align-items: center;
}

.react_modal.centered.show .modal_content {
  transform: translateY(0);
}
