.container {
  background-color: #1e3b47;
}

.content {
  min-height: 100vh;
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
}

.leftPane {
  width: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-image: url('../assets/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rightPane {
  width: 50%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #1e3b47;
  background-image: url('../assets/logo-bg-dark.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: 100%;
}

.pitchContent {
  width: 60%;
  max-width: 700px;
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pitchContent > p {
  margin: 0 0 16px 0;
}

.heroText {
  composes: heading-1 from './theme.module.css';
  color: #ffffff;
  margin: 24px 0;
  text-transform: capitalize;
}

.subText {
  composes: body-1 from './theme.module.css';
  color: rgba(255, 255, 255, 0.85);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.subTextContainer > .subText {
  margin-bottom: 16px;
}

.secondaryButtonDark {
  composes: button-2 from './theme.module.css';
  composes: secondaryButtonDark from './theme.module.css';
  width: 201px;
  margin: 48px 0 0 0;
}

@media (max-width: 1243px) {
  .container {
    border-radius: 0;
  }

  .pitchContent {
    padding: 80px 60px;
  }
}

@media (max-width: 768px) {
  .rightPane {
    display: none;
  }

  .leftPane {
    width: 100%;
    height: unset;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background-image: none;
    margin-top: 100px;
  }

  .heroText {
    padding: 0 24px;
  }

  .subTextContainer > .subText:last-child {
    margin-bottom: 32px;
  }

  .subText {
    padding: 0 24px;
  }

  .secondaryButtonDark {
    margin: 24px;
    align-self: center;
  }

  .subTextContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
