.container {
  composes: container from './theme.module.css';
  padding: 150px 222px;
  background: #0c232d;
}

@media (min-width: 391px) and (max-width: 768px) {
  .container {
    padding: 24px !important;
  }
}

@media (min-width: 320px) and (max-width: 390px) {
  .container {
    padding: 0 !important;
  }
}

.mainContent {
  composes: mainContent from './theme.module.css';
  width: 100% !important;
  justify-content: space-between;
}

.mainContent div:first-child {
  width: 70%;
  padding-right: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.heading {
  composes: heading-2 from './theme.module.css';
  color: #ffffff;
  margin: 0;
}

.topHeading {
  composes: heading-4 from './theme.module.css';
  color: #dbc6aa;
  margin: 0 0 32px 0;
}

.primaryButton {
  composes: button-2 from './theme.module.css';
  composes: primaryButton from './theme.module.css';
  width: 275px;
}

.secondaryButtonDark {
  composes: button-2 from './theme.module.css';
  composes: secondaryButtonDark from './theme.module.css';
  width: 275px;
  margin-top: 16px;
}

@media (max-width: 960px) {
  .mainContent {
    flex-direction: column;
    align-items: center;
    padding: 24px;
  }

  .primaryButton {
    margin-top: 48px;
  }

  .content {
    align-items: center;
  }

  .mainContent div:first-child {
    width: 100%;
    padding-right: 0;
  }

  .topHeading {
    text-align: center;
  }

  .heading {
    margin-top: 24px;
    text-align: center;
  }
}
