.container {
  overflow: hidden;
  display: flex;
  width: 100%;
  border-radius: 10px;
}

.featureContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
  width: 50%;
}

.featureContainer > img {
  width: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
  text-align: center;
  flex-grow: 1;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px 32px 32px;
}

.customQuote {
  background: #fafafa;
}

.features {
  composes: body-2 from './theme.module.css';
  padding: 10px 0;
  color: #4f4f4f;
  font-size: 1rem;
  composes: secondaryFont from './theme.module.css';
}

.primaryButton {
  composes: button-3 from './theme.module.css';
  composes: primaryButton from './theme.module.css';
  width: 300px;
  margin: 16px 0;
}

.info {
  composes: body-2 from './theme.module.css';
  color: #828282;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100vw;
  }

  .primaryButton {
    width: 100%;
  }

  .features {
    font-size: 0.8rem;
  }
}
