.placesInput {
  border-radius: 4px;
  width: 250px;
  padding: 16px;
  margin: 0px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.inputContainer {
  display: flex;
  flex-direction: column;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 6px;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  cursor: pointer;
  padding: 0 10px;
}

.menuItem > span {
  cursor: pointer;
}

.menuItem:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

@media (max-width: 768px) {
  .placesInput {
    width: 100%;
  }
}
