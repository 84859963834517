.container {
  composes: container from './theme.module.css';
  padding-bottom: 0;
  flex-direction: column;
  background-color: #0c232d;
}

@media (min-width: 391px) and (max-width: 768px) {
  .container {
    padding: 0 24px !important;
  }
}

@media (min-width: 320px) and (max-width: 390px) {
  .container {
    padding: 0 0 !important;
  }
}

.mainContent {
  composes: mainContent from './theme.module.css';
}

.textContainer {
  padding: 85px;
}

.heading {
  composes: heading-2 from './theme.module.css';
  color: #ffffff;
}

.topHeading {
  font-size: clamp(1.375rem, 1.2719rem + 0.3984vw, 1.75rem);
  color: #dbc6aa;
  margin-bottom: 32px;
}

.mainHeading {
  font-size: clamp(1.875rem, 1.2908rem + 2.2576vw, 4rem);
}

.smallHeading {
  font-size: clamp(1.375rem, 1.2719rem + 0.3984vw, 1.75rem);
}

.extraSmallHeading {
  font-size: clamp(1.25rem, 1.2156rem + 0.1328vw, 1.375rem);
  margin: 16px 0 32px 0;
  line-height: 120%;
  text-align: center;
}

.textContainer > .smallHeading {
  padding-bottom: 16px;
}

.description {
  composes: body-2 from './theme.module.css';
  color: rgba(255, 255, 255, 0.85);
}

.description > strong {
  color: white;
  font-weight: 700;
}

.secondaryHeading {
  composes: heading-3 from './theme.module.css';
  text-align: center;
  padding-top: 50px;
}

.image {
  width: 100%;
  min-height: 280px;
}

.boxSection {
  background-color: #0c232d;
  padding: 48px 120px 200px 120px;
}

.boxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 32px;
}

.boxSection > .mainContent {
  flex-direction: column;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  max-width: 384px;
  min-width: 224px;
  height: 249px;
  min-height: 230px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  text-align: center;
  margin: 24px;
}

.boxContent {
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxFilled {
  justify-content: center;
  background-color: #1e3b47;
  border: 1px solid #1e3b47;
}

.boxIcon {
  height: clamp(4rem, 3.3815rem + 2.3904vw, 6.25rem);
  width: clamp(4rem, 3.3815rem + 2.3904vw, 6.25rem);
  max-height: 64px;
  max-width: 64px;
}

.primaryButton {
  composes: button-3 from './theme.module.css';
  composes: primaryButton from './theme.module.css';
  width: 141px;
  height: 54px;
}

/* Step UI */

.stepWrapper {
  padding: 32px 0;
  width: 95%;
}

.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}

.StepProgress::before {
  display: inline-block;
  content: '';
  position: absolute;
  top: 15px;
  left: 20px;
  width: 10px;
  height: 80%;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
}

.StepProgressItem {
  position: relative;
  counter-increment: list;
}

.StepProgressItem:not(:last-child) {
  padding-bottom: 20px;
}

.StepProgressItem::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 9px;
  left: -37px;
  width: 13px;
  height: 13px;
  border: 6px solid #dbc6aa;
  outline: 6px #0c232d;
  border-radius: 50%;
  background-color: #0c232d;
}

.StepProgressItem:first-child::after {
  top: 15px;
}

.stepContent {
  display: flex;
  align-items: center;
}

.stepContent > p {
  flex-grow: 1;
  margin: 0 11px;
}

.stepNumber {
  composes: heading-3 from './theme.module.css';
  color: rgba(255, 255, 255, 0.25);
}

@media (max-width: 510px) {
  .StepProgress::before {
    top: 30px;
    height: 75%;
  }

  .StepProgressItem:first-child::after {
    top: 30px;
  }
}

@media (max-width: 585px) {
  .image {
    width: 97vw !important;
  }

  .break {
    display: none;
  }
}

@media (max-width: 768px) {
  .boxSection {
    padding-bottom: 32px !important;
  }

  .StepProgressItem:not(:first-child)::after {
    top: 4px;
  }
}

@media (min-width: 768px) and (max-width: 960px) {
  .StepProgressItem:not(:first-child)::after {
    top: 8px;
  }
}

@media (min-width: 768px) {
  .boxContainer {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

@media (max-width: 960px) {
  .mainContent {
    width: 100%;
    flex-direction: column;
  }

  .image {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }

  .textContainer {
    padding: 32px 0 0 0;
  }

  .mainContent {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .box {
    max-width: unset;
    margin: 16px;
  }
}
