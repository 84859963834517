.selectInputMenu {
  position: absolute;
  top: 56px;
  z-index: 2;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.45) !important;
}

.selectInputItem {
  cursor: pointer;
}
.selectInputContainer {
  position: relative;
  cursor: pointer;
}
.selectInput {}

.clickOutside {
  position: fixed;
  z-index: 0;
  top: 0px;
  left: 0px;
  height: 500px;
  width: 100vw;
}
