.container {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px 0px 0px 16px;
  width: 588px;
}

.containerQuote {
  composes: container;
  margin-top: 32px;
  align-items: center;
}

.smallText {
  composes: body-3 from './theme.module.css';
  color: rgba(33, 50, 37, 0.5);
}

.tabs {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 50, 37, 0.15);
}

.tabButton {
  composes: linkButton from './theme.module.css';
  border-bottom: none;
  padding: 16px 0 24px 0;
  width: 33.3333%;
  min-width: 130px;
  transition: background-color 0.15s ease-in;
  line-height: 1.1 !important;
  height: 36px !important;
}

.tabButton:hover {
  border-bottom: 2px solid #1e3b47;
}

.tabButton.active {
  border-bottom: 2px solid #dbc6aa;
  transition: background-color 0.15s ease-in;
}

.mainText {
  composes: heading-5 from './theme.module.css';
  font-size: clamp(1rem, 0.8969rem + 0.3984vw, 1.375rem);
  margin-bottom: 24px;
}

.quoteTitle {
  composes: heading-7 from './theme.module.css';
  text-align: center;
}

.mainContent {
  width: 100%;
  display: grid;
  grid-template-columns: auto max-content;
  padding: 32px 0 24px 0;
  flex-grow: 1;
  align-items: center;
}

.mainField {
  display: flex;
  flex-direction: column;
}

.locationSearch {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.locationSearchBusiness {
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.locationSearchBusiness > p {
  margin-bottom: 0;
  margin-right: 24px;
}

.priceSelector {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
}

.price {
  align-items: center;
  padding: 12px 0px;
  width: 100%;
  height: 26px;
  border-radius: 14px;
  composes: heading-5 from './theme.module.css';
  font-size: clamp(1.375rem, 1.2719rem + 0.3984vw, 1.75rem);
  text-align: center;
  color: #1e3b47;
}

.primaryButton {
  composes: button-2 from './theme.module.css';
  composes: primaryButton from './theme.module.css';
  composes: fullWidth from './theme.module.css';
}

.secondaryButton {
  composes: button-2 from './theme.module.css';
  composes: secondaryButton from './theme.module.css';
  composes: fullWidth from './theme.module.css';
  margin-top: 16px;
}

.addressField {
  width: 300px;
}

@media (max-width: 1196px) {
  .container {
    border-radius: 0;
    width: 100%;
  }

  .tabButton {
    height: 70px !important;
  }
}

@media (max-width: 960px) {
  .containerQuote {
    margin-top: 16px;
  }

  .mainContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 16px 0;
  }

  .tabButton {
    padding: 0;
    height: 70px !important;
    min-width: unset;
    font-size: 14px !important;
  }

  .locationSearch {
    display: block;
    margin-top: 8px;
  }

  .priceSelector {
    width: 100%;
    flex-direction: unset;
    display: grid;
    grid-template-columns: auto max-content;
    height: unset;
  }

  .priceSelector .mainText {
    font-size: 14px;
  }

  .mainText {
    margin-bottom: 16px;
  }

  .price {
    margin-top: 0;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    width: max-content;
  }
}
