.container {
  composes: container from './theme.module.css';
}

@media (min-width: 769px) and (max-width: 1369px) {
  .container {
    padding: 200px 24px;
  }
}

@media (min-width: 391px) and (max-width: 768px) {
  .container {
    padding: 0 24px !important;
  }
}

@media (min-width: 320px) and (max-width: 390px) {
  .container {
    padding: 0 !important;
  }
}

.primaryButton {
  composes: button-2 from './theme.module.css';
  composes: primaryButton from './theme.module.css';
  width: 171px;
  height: 64px !important;
  border-radius: 14px;
}

.secondaryButton {
  composes: button-2 from './theme.module.css';
  composes: secondaryButton from './theme.module.css';
  width: 201px;
  height: 64px !important;
  border-radius: 14px;
  margin-left: 16px;
}

.buttonContainer {
  display: flex;
  margin-top: 48px;
}

@media (max-width: 787px) {
  .primaryButton,
  .secondaryButton {
    align-self: center;
    min-width: 60%;
  }

  .buttonContainer {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .buttonContainer > button {
    margin: 0;
  }

  .buttonContainer > a:first-child {
    margin-bottom: 16px;
  }
}
