.container {
  composes: container from './theme.module.css';
  background-color: #f1f1f1;;
}

.mainContent {
  composes: mainContent from './theme.module.css';
  flex-direction: column;
  align-items: center;
}

.containerB2b {
  background-color: white;
  text-align: center;
}

.heading {
  composes: heading-4 from './theme.module.css';
  text-align: center;
  color: #000000;
  padding-bottom: 48px;
}

.headingB2b {
  font-size: clamp(2.125rem, 1.6096rem + 1.992vw, 4rem);
  color: black;
  padding-bottom: 32px;
}

.description {
  composes: primaryFont from './theme.module.css';
  font-weight: 400;
  font-size: clamp(1.125rem, 1.0563rem + 0.2656vw, 1.375rem);
  line-height: 150%;
  color: rgba(0, 0, 0, 0.75);
  width: 70%;
  margin-bottom: 72px;
}

.logoContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  overflow-x: scroll;
  margin-bottom: 2rem;
}

.logoContainer::-webkit-scrollbar {
  display: none;
}

.logoContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.logo {
  padding: 0px;
  width: 500px;
  fill-opacity: 0;
}

.logoDark {
  width: 100%;
  height: 50px;
  margin-top: 15px;
  padding-left: 40px;
  filter: brightness(0) invert(1);
}

.logoNormal {
  width: 100%;
  height: 50px;
  margin-top: 15px;
  padding-left: 5px;
  filter: brightness(0);
  opacity: 0.8;
}

.logoLight {
  padding: 0px;
  width: 500px;
  fill-opacity: 0;
}

@media only screen and (max-width: 1159px) {
  .container {
    padding: 80px 0;
  }

  .logoContainer {
    width: 100%;
  }

  .description {
    margin-bottom: 24px;
    width: 95%;
    text-align: left;
  }
}

.sectorsContainer {
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: center;
}

.sectorsContainer::-webkit-scrollbar {
  display: none;
}

.sectorsWrapper {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.sectorTitles {
  width: 100%;
}

.sectorItemsContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
}

.sectorItemsColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sectorTitle {
  font-size: 14px !important;
  text-align: left !important;
  color: #000000;
  font-weight: 700 !important;
  line-height: 1.4;
  margin-top: 1rem;
  margin-bottom: 1rem !important;
  margin: 5px;
}

.sectorItem {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  text-align: left;
  line-height: 1.4;
  margin: 5px;
}

/* First breakpoint for tablet */
@media (max-width: 768px) {
  .sectorItemsContainer {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .sectorItemsColumn:nth-child(-n+3) {
    grid-row: 1;
  }

  .sectorItemsColumn:nth-child(n+4) {
    grid-row: 2;
    margin-top: 1rem;
  }

  .sectorsWrapper {
    padding: 0 1rem;
  }

  .sectorTitle {
    font-size: 13px !important;
  }

  .sectorItem {
    font-size: 13px;
  }
}

/* New breakpoint for mobile */
@media (max-width: 480px) {
  .sectorItemsContainer {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .sectorItemsColumn {
    margin-top: 0 !important;
    grid-row: auto !important;
  }

  .sectorItemsColumn:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
  }

  .sectorsWrapper {
    padding: 0 ;
  }

  .sectorTitle {
    font-size: 15px !important;
    margin-bottom: 1.2rem !important;
  }

  .sectorItem {
    font-size: 14px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.homeDepotLogo {
  filter: none !important;
  -webkit-filter: none !important;
  fill: none !important;
  color: inherit !important;
}

.buttonText {
  /* composes: heading; */
  line-height: 24px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  letter-spacing: 2px !important;
  text-align: center !important;
  color: #000000;
  text-decoration: none !important;
}

.button {
  composes: buttonText;
  height: 60px;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  transition: color 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: background 0.2s !important;
  transition: outline 0.1s !important;
  z-index: 0 !important;
  padding: 1rem;
  border-style: none !important;
}


.primaryButton {
  composes: button;
  background-color: #dbc6aa;
  transition: background 0.2s;
}
